@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');

/* Global styles */
body {
    background-image: url('./pricing-header.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Roboto Slab', serif;
}

.pricing-container {
    text-align: center;
    margin: 10px;
}

/* Pricing section styles */
.pricing-section {
    border-radius: 12px;
    background: #CAE7E3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;  /* Reduced margin */
}

.pricing-section.left-align {
    justify-content: flex-start;
}

.pricing-section.right-align {
    justify-content: flex-end;
}

.image-container {
    display: flex;
    flex: 1;
    max-width: 65%;
    justify-content: center; /* Centers single image */
    align-items: center;
}

.product-img {
    flex: 1;
    max-width: calc(50% - 10px); /* Allow for some margin */
    border-radius: 12px;
    margin: 5px; /* Reduced margin */
}

.text-content {
    flex: 1;
    max-width: 50%;
    padding: 15px; /* Reduced padding */
}

.left-img {
    flex-direction: row;
}

.right-img {
    flex-direction: row-reverse;
}

.left-img, .right-img {
    display: flex;
}

.left-img .text-content, .right-img .text-content {
    order: 1;
}

.right-img .product-img {
    order: -1;
}

.text-content h2 {
    font-size: 2rem;
}

.text-content p {
    font-size: 1.2rem;
}

.pricing-info, .product-info {
    margin-top: 20px;
}

.pricing-info h3, .product-info h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.pricing-info p, .product-info p {
    font-size: 1.2rem;
}

.hero-section {
    position: relative;
    color: white;
    text-align: center;
    padding: 3rem 0; /* Reduced padding */
}

.hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
            rgba(0, 0, 0, 0.6),  /* Increased opacity */
            rgba(0, 0, 0, 0.6)
    );
    z-index: -1;
}

.hero-section h1,
.hero-section p {
    z-index: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.hero-section h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 15px; /* Reduced margin */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Adjusted shadow */
}

.hero-section p {
    font-size: 1.4rem;
    font-weight: 300;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.text-content h2 {
    font-size: 3.5rem;  /* Increased from 2rem */
}

.text-content p,
.pricing-info p,
.product-info p {
    font-size: 2.4rem;  /* Increased from 1.2rem */
}

/* Add these new rules for the bullet points */
.product-info ul {
    list-style-position: inside;
    margin-left: 0;
    padding-left: 1em;
}

.addons-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
}

.addons-column {
    flex: 1;
    padding: 0 15px;
}


.product-info li {
    font-size: 1.4rem;
    margin-bottom: 0.5em;
}

@media screen and (max-width: 768px) {
    .pricing-section,
    .addons-info {
        flex-direction: column;
        align-items: center;
    }

    .image-container,
    .text-content,
    .addons-column {
        max-width: 100%;
    }

    .text-content,
    .addons-column {
        padding: 10px;
    }

    .addons-info {
        flex-direction: column;
    }
}