.terms-container {
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.accordion-title {
    font-size: 18px;
    font-weight: 500;
}

.accordion-content {
    font-size: 16px;
    line-height: 1.5;
    margin: 10px 0;
}

.terms-footer {
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}
