/* Container */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
    margin: auto;
    transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
    .gallery-container {
        grid-template-columns: repeat(2, 1fr); /* Reducing columns */
        grid-gap: 1rem;
        padding: 1rem;
    }

    .card-wrapper {
        border-radius: 10px; /* Smaller radius */
    }
}

/* Card Wrapper */
.card-wrapper {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
}

.card-wrapper.hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.card-wrapper .ui.card {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Thumbnail */
.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

/* Modal */
.ui.modal > .header {
    font-size: 1.5rem;
    font-weight: 500;
}

.ui.modal > .content > img {
    max-height: 80vh;
}

.ui.modal > .actions {
    display: flex;
    justify-content: flex-end;
}
