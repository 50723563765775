.service-calculator-container {
    font-family: 'Helvetica, Arial, sans-serif';
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.service-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.service-item span {
    flex: 1;
    min-width: 50%; /* allows text to not get too squeezed */
}

.service-item button,
.service-item input {
    flex: 0;
    margin-left: 10px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .service-item {
        flex-direction: column;
        align-items: stretch;  /* Stretch to full width */
    }
    .service-item span {
        margin-bottom: 10px;
        text-align: center;
    }
    .service-item button,
    .service-item input {
        margin: 5px auto;
        display: block;
    }
}