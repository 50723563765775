@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.main-header {
    position: relative;
    background-color: #CAE7E3 !important;
    box-shadow: none !important;
    border: none !important;
    padding: 2em !important;
    font-family: 'Open Sans', sans-serif !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-subtext, .mobile-logo-subtext {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.5em;
    color: #575757;
    margin-top: 15px;  /* adjust this value to move it lower */
    text-align: center; /* center-align text */
}

.logo-text-container {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.logo-text-container, .mobile-logo-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-text {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 4.5em;
    font-weight: bold;
    color: #FFB6C1;
}

.mobile-logo-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mobile-logo-text {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2em;
    font-weight: bold;
    color: #FFB6C1;
}

.header-logo {
    width: 80px !important;
    height: auto !important;
    margin-right: auto;
    border-radius: 10%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.header-logo:hover {
    transform: scale(1.05);
}

.main-header .menu > .item {
    border-left: none !important;
}

.main-header a {
    color: #575757 !important;
}

.main-header a:hover {
    color: #FEC7BC !important;
}

.main-header .icon {
    margin: 0 5px;
    color: #575757 !important;
}

.main-header .icon:hover {
    color: #FEC7BC !important;
}

.ui.primary.button {
    background-color: #FEC7BC !important;
    color: white !important;
    font-size: 0.9em !important;
    padding: 0.5em 1em !important;
}

.header-item .ui.primary.button {
    margin-left: -2px !important;  /* Reduced margin */
    margin-right: -2px !important; /* Reduced margin */
}

@media only screen and (max-width: 768px) {
    .header-logo {
        width: 70px !important;
        height: auto !important;
    }

    .main-header a {
        font-size: 0.9em !important;
    }

    .main-header {
        padding: 0.7em !important;
    }
}

.mobile-header {
    position: relative;
    width: 100%;  /* Updated width */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;  /* To center-align the header */
}

.mobile-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.mobile-dropdown i.icon {
    font-size: 1.5em;
    color: #575757 !important;
    transition: color 0.3s ease-in-out;
}

.mobile-dropdown:hover i.icon {
    color: #FEC7BC !important;
}

.full-screen-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 182, 193, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close-menu-button {
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    cursor: pointer;
    font-size: 1.5em;
}

.full-screen-dropdown.visible {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .item {
    font-size: 1.2em;
    padding: 1em;
    color: white !important;
    text-align: center;
    width: 100%;
}

.mobile-menu .item:hover {
    background-color: #FEC7BC;
}
