/* Services.css */

/* Container Styling */
.services-container {
    background-color: #CAE7E3;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header Text Styling */
.header-title,
.header-description,
.book-now-title,
.book-now-description,
.card-header {
    color: #333;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
}

.header-title,
.book-now-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.header-description,
.book-now-description {
    font-size: 18px;
    color: #555;
}

/* Card Styling */
.service-card {
    background-color: #FEC7BC;
    color: #333;
    margin: 20px;
    box-sizing: border-box;
    cursor: pointer; /* Makes it clear that the card is clickable */
}

.modal-content {
    text-align: center;
}

.service-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: all 0.3s ease;
}

.image-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-slider button {
    background-color: #FEC7BC;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1em;
    color: #333;
}

.service-calculator-section {
    margin-top: 50px;
    padding: 20px;
    background-color: #F8F8F8;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 16px;
}

.slider-image {
    max-height: 400px;
    object-fit: cover;
    margin: auto;
    display: block;
}

.image-slider img {
    max-width: 5%; /* Limit image width */
    height: auto;
}

.service-card {
    width: calc(33.33% - 20px);
    padding: 20px;
}

.card-header {
    font-size: 1.5em;
}

.price {
    font-size: 1.2em;
    font-weight: bold;
    color: #d9534f;
}

.more-info-icon {
    float: right;
    cursor: pointer;
}

/* Book Now Section */
.book-now-section {
    text-align: center;
    margin-top: 30px;
}

.book-now-button {
    background-color: #FEC7BC;
    color: #333;
    margin-top: 20px;
}

/* Extra Services Section */
.extra-services {
    margin-top: 50px;
}

.extra-service-accordion {
    background-color: #CAE7E3;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #333;
}

.extra-service-accordion .title,
.extra-service-accordion .content {
    padding: 20px;
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
    .service-card {
        width: calc(50% - 20px);
    }
}

@media only screen and (max-width: 768px) {
    .service-card {
        width: 100%;
    }
}

.close-button {
    position: absolute;
    right: 20px;
    top: 10px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .Card.Group {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        width: 100% !important;
        margin: 10px 0 !important;
        box-sizing: border-box !important;
    }

    .header-title,
    .header-description,
    .book-now-title,
    .book-now-description,
    .card-header {
        font-size: 0.8em;
    }

    .book-now-button {
        padding: 10px 20px;
    }
}
