
.home-container {
    padding: 20px; /* Reduced from 50px */
}

.about-section{
    padding: 2em;
    background: #CAE7E3;
}

.top-section {
    background-color: #CAE7E3;
    padding: 20px;  /* Reduced from 20px */
    text-align: center;
}

.top-section h1 {
    color: #575757;
    margin-top: 0;  /* Add this line to remove any default margin */
}

.top-section p {
    color: #555;
}

.top-section p, .bottom-section p, .about-section p {
    font-size: 18px;  /* larger text */
    line-height: 1.6;  /* better line height */
    color: #333333;  /* darker text for better contrast */
}

.top-section h1, .bottom-section h1, .about-section h2 {
    font-size: 36px;  /* larger headers */
    color: #222222;  /* darker headers for better contrast */
    margin-bottom: 20px; /* spacing */
}


.middle-section {
    padding: 20px;
    text-align: center;
}

.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle-section h2 {
    color: #575757;
}

.bottom-section {
    background-color: #CAE7E3;
    padding: 20px;
    text-align: center;
}

.bottom-section p {
    color: #555;
}

.bottom-section .button {
    background-color: #8ABDB7;
    color: #575757;
}

.coming-soon {
    font-size: 1.2em;
    color: #888;
    padding: 20px;
}

.top-section {
    position: relative;
    width: 100%;
    height: 80vh;
    background: url('./hero-home.jpg') no-repeat center center;
    background-size: cover;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.carousel .slide {
    position: relative;
    padding-bottom: 75%; /* for 4:3 aspect ratio */
    height: 0;
    overflow: hidden;
}

.carousel .slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-section {
    text-align: center;
}

/* Existing styles */
/* ... */

/* Mobile optimization */
/* ... Your existing CSS ... */

/* Mobile-specific styles */
@media (max-width: 768px) {
    /* Existing mobile rules */
    .home-container {
        padding-left: 0;
        padding-right: 0;
    }

    .top-section, .bottom-section, .about-section {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin: 0;
    }

    /* Mobile-specific styles */
    @media (max-width: 768px) {
        /* ... existing rules ... */

        .about-section {
            text-align: center;
        }

        .button {
            margin: 10px 5px;
            font-size: 18px;
            padding: 12px 20px;
            display: inline-block;  /* Make sure the buttons maintain inline properties */
        }
    }


    .button {
        margin: 10px 5px;
        font-size: 18px;
        padding: 12px 20px;
    }

    /* New additions for better text readability */
    .top-section p, .bottom-section p, .about-section p {
        font-size: 16px;  /* Adjusted for mobile */
        line-height: 1.5;  /* Adjusted for mobile */
    }

    .top-section h1, .about-section h2 {
        font-size: 28px;  /* Adjusted for mobile */
    }
}

.bottom-section .ui.small.images img {
    width: 450px;  /* Adjust as needed */
    height: 450px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 15px;  /* Adds rounded corners */
}