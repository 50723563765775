.additional-services-container {
    background-color: #f4f4f4;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.additional-services-header {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.additional-services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.additional-service-card {
    background: white;
    border-radius: 8px;
    margin: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33% - 30px);
    text-align: center;
}

.additional-service-card h3 {
    font-size: 1.2em;
}

.additional-service-card p {
    font-size: 1em;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .additional-service-card {
        width: calc(50% - 30px);
    }
}

@media only screen and (max-width: 480px) {
    .additional-service-card {
        width: 100%;
    }
}
