.contact-container {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./contact.webp');
    background-size: cover;
    color: white; /* You may want to tweak this color as well depending on how light the background becomes */
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-size: 1.25em;
}

.contact-text {
    color: white !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.contact-container .ui.form label {
    color: white !important;
}

.contact-container .ui.grid .column {
    text-align: center; /* To center the text and image */
}

h1 {
    font-family: 'Playfair Display', serif;
    color: white !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.button {
    background-color: #FEC7BC !important;
    color: #575757 !important;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #E4AFA0 !important;
}

/* Existing CSS */

/* Mobile View */
@media screen and (max-width: 768px) {
    .contact-container {
        padding: 20px;
    }

    .ui.form label {
        font-size: 14px;
    }

    h1 {
        font-size: 24px;
    }

    .ui.button {
        font-size: 14px;
    }
}

.autocomplete-dropdown {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    max-height: 150px !important;
    overflow-y: auto !important;
}
