.success-container {
    background-color: #CAE7E3;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.home-button {
    background-color: #FEC7BC;
    color: white;
}
