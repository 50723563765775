.footer-container {
    background-color:  #CAE7E3;  /* Maintaining the existing color */
    color: #fff;  /* Making the text white for better readability */
    padding: 30px 0;  /* Increased padding for a spacious look */
    border-top: 2px solid #666;  /* Maintaining the existing border */

    /* Applying Flexbox for center alignment */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-container p,
.footer-container a {
    margin-bottom: 15px;  /* Adds some space between text and button */
    font-size: 16px;  /* Increases the font size */
}

.footer-container .button {
    background-color: #575757;  /* Dark button background */
    color: #fff;  /* White button text */
}
